<template>
    <layout>
        <page-header :title="title" :items="items" />

        <div class="card-box">
            <awesome-table
                id="logistics-center"
                :labels="labels"
                :items="orderProductColors"
                :options="{
                    addButton: false,
                    sort: true,
                    filters: true,
                    columnsSelector: true,
                    csvExport: false,
                    isCard: false,
                    tableStyle: 'font-size: 12px;'
                }"
                :pagination="pagination"
                :is-loading="isLoading"
                :filters="filters"
                :get-row-class="getRowClass"
                @row-click="$router.push(`/logistics-center/${$event.id}`)"
                @mouse-wheel-click="$openNewTab(`/logistics-center/${$event.id}`)"
                @pagination-change="onPaginationChange"
                @selection-change="onSelectionChange"
                @filter-change="onFilterChange"
            >
                <template #[`items.order.order_number`]="{ item }">
                    {{ item.order.order_number || item.order.magento_order_number }}
                    <a
                        class="action-icon"
                        @click.stop="copyToClipboard(item.order.order_number || item.order.magento_order_number)"
                    >
                        <i class="far fa-copy" />
                    </a>
                </template>
                <template #[`items.gender`]="{ item }">
                    <span v-if="item.account.genderIdentifier === 'gender_men'">
                        Man
                    </span>
                    <span v-else-if="item.account.genderIdentifier === 'gender_women'">
                        Woman
                    </span>
                </template>
                <template #[`items.need_impression_kit`]="{ value }">
                    <span :class="getImpressionKitBadgeClass(value)">
                        {{ value === true ? 'YES' : 'NO' }}
                    </span>
                </template>
                <template #[`items.order.customer.last_orders`]="{ item }">
                    <span v-for="(order, index) in getDisplayableOrders(item.order)" :key="index">
                        {{ order.order_number }} <br />
                    </span>
                    <b v-if="getDisplayableOrders(item.order, true)">
                        {{ `${getDisplayableOrders(item.order, true)} more...` }}
                    </b>
                </template>
                <template #[`items.order.customer.first_name`]="{ item }">
                    {{
                        `${item.order.customer.first_name}
                        ${item.order.customer.last_name}`
                    }}
                </template>
                <template #[`items.print_sticker`]="{ item }">
                    <button
                        class="btn btn-xs btn-info"
                        @click.stop="dymoPrintLabel(item, 'order', true)"
                    >
                        Order
                    </button>
                    <button
                        class="btn btn-xs btn-info ml-1"
                        @click.stop="dymoPrintLabel(item, 'fda')"
                    >
                        FDA
                    </button>
                    <button
                        class="btn btn-xs btn-blue ml-1"
                        @click.stop="dymoPrintLabel(item, 'both', true)"
                    >
                        Both
                    </button>
                </template>
                <template #[`items.outgoing_tracking`]="{ item }">
                    {{ item.outgoing_tracking || '-' }}
                    <a
                        v-if="item.outgoing_tracking"
                        class="action-icon"
                        @click.stop="copyToClipboard(item.outgoing_tracking)"
                    >
                        <i class="far fa-copy" />
                    </a>
                </template>
                <template #[`items.incoming_tracking`]="{ item }">
                    {{ item.incoming_tracking || '-' }}
                    <a
                        v-if="item.incoming_tracking"
                        class="action-icon"
                        @click.stop="copyToClipboard(item.incoming_tracking)"
                    >
                        <i class="far fa-copy" />
                    </a>
                </template>
                <template #[`items.scan_link`]="{ item }">
                    <a
                        v-if="item.scan_link"
                        :href="item.scan_link"
                        target="_blank"
                        class="btn btn-xs btn-blue"
                        @click.stop
                    >
                        3D Scan
                    </a>
                </template>
                <template #[`items.order.shipping_address`]="{ item }">
                    <div class="d-flex align-items-center justify-content-center">
                        <span>
                            {{
                                `${item.order.customer.first_name}
                                ${item.order.customer.last_name}`
                            }}
                            <br />
                            {{ item.order.shipping_address }} <br />
                            {{ item.order.shipping_city }} <br />
                            {{
                                `${item.order.shipping_state},
                                ${item.order.shipping_zip}`
                            }}
                            <br />
                            {{ item.order.shipping_country }}
                        </span>
                        <a
                            class="action-icon ml-1"
                            @click.stop="copyAddressToClipboard(item.order )"
                        >
                            <i class="far fa-copy" />
                        </a>
                    </div>
                </template>
                <template #[`items.productColor.product.sku`]="{ item }">
                    {{ item.productColor.product.sku || item.assigned_sku }}
                </template>
                <template #[`items.order.customer.orders_count`]="{ item }">
                    {{ item.order.customer.orders.length }}
                    <a
                        :href="`${appUrl}/customers/${item.order.customer_id}/orders`"
                        target="_blank"
                        class="action-icon"
                        @click.stop
                    >
                        <i class="fe-external-link" />
                    </a>
                </template>
                <template #[`items.priority`]="{ value }">
                    <span :class="getBadgeClass(value)">
                        {{ value | startCase }}
                    </span>
                </template>
                <template #[`items.warehouse`]="{ value }">
                    {{ value.name }}
                </template>
            </awesome-table>
        </div>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import config from '@/config';
import appConfig from '@src/app.config';
import dymoPrinterMixin from '@src/mixins/dymo-printer.mixin.js';

export default {
    page: {
        title: 'Logistics Center',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    mixins: [dymoPrinterMixin],

    data() {
        const {
            page: currentPage = 1,
            perPage = 10,
            sortBy = 'order.created_at',
            order = 'DESC',
            q = null,
            filters = '{}'
        } = this.$route.query;

        return {
            title: 'Logistics Center',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Logistics Center',
                    active: true
                }
            ],
            labels: [
                {
                    text: 'Order Number',
                    value: 'order.order_number',
                    width: '140px'
                },
                {
                    text: 'Order Date',
                    value: 'order.created_at',
                    filter: 'formatDate',
                    type: 'date'
                },
                {
                    value: 'quantity',
                    sortable: false,
                    filterable: false
                },
                {
                    text: 'Customer Name',
                    value: 'order.customer.first_name',
                    sortable: false
                },
                {
                    value: 'gender',
                    sortable: false,
                    filterable: false
                },
                {
                    text: 'Shoe Size',
                    value: 'size',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'need_impression_kit',
                    type: 'enum',
                    enumOptions: [
                        {
                            text: 'Yes',
                            value: true
                        },
                        {
                            text: 'No',
                            value: false
                        }
                    ]
                },
                {
                    text: 'Customer Orders',
                    value: 'order.customer.last_orders',
                    sortable: false,
                    filterable: false,
                    width: '140px'
                },
                {
                    value: 'print_sticker',
                    sortable: false,
                    filterable: false,
                    width: '190px'
                },
                {
                    value: 'outgoing_tracking',
                    sortable: false,
                    width: '280px'
                },
                {
                    value: 'incoming_tracking',
                    sortable: false,
                    width: '280px'
                },
                {
                    text: '3D Scan',
                    value: 'scan_link',
                    sortable: false,
                    filterable: false,
                    width: '120px'
                },
                {
                    text: '3D Scan Date',
                    value: 'scan_date',
                    filter: 'formatDateOnly',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'status',
                    type: 'enum',
                    enumOptions: [
                        'ORDER RECEIVED',
                        'SENT IMPRESSION KIT',
                        'SCANNED & READY FOR DESIGN',
                        'DESIGNED & READY FOR PRODUCTION',
                        'PRODUCTION PROCESS WITH SHIPMENT',
                        'SHIP TO CUSTOMER',
                        `DESIGN - WAITING ON CUSTOMER'S REPLY`,
                        'ENTERING PRODUCTION LINE',
                        'HOLD',
                        'ADDRESS VERIFICATION',
                        'RTS',
                        'OLD ORDER',
                        `REM - WAITING FOR CUSTOMER'S REPLY`,
                        'REM - DESIGNED & READY FOR PRODUCTION',
                        'REM - PRODUCTION',
                        'REM - SHIP TO CUSTOMER',
                        'REQUEST FOR CANCELATION PRE SHIP',
                        'CANCELED PRE SHIP',
                        'REQUEST FOR CANCELATION AFTER SHIP',
                        'CANCELED',
                        'ITEM HAS BEEN RETURNED'
                    ]
                },
                {
                    text: 'Est Delivery Date',
                    value: 'est_delivery_date',
                    sortable: false,
                    filterable: false
                },
                {
                    text: 'Shipping Address',
                    value: 'order.shipping_address',
                    sortable: false,
                    filterable: false,
                    width: '180px'
                },
                {
                    text: 'SKU',
                    value: 'productColor.product.sku',
                    sortable: false,
                    filterable: false,
                },
                {
                    text: 'Customer Orders Number',
                    value: 'order.customer.orders_count',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'priority',
                    type: 'enum',
                    enumOptions: [
                        {
                            text: 'Normal',
                            value: 'NORMAL'
                        },
                        {
                            text: 'High',
                            value: 'HIGH'
                        },
                        {
                            text: 'Very High',
                            value: 'VERY_HIGH'
                        },
                        {
                            text: 'Expedited Done',
                            value: 'EXPEDITED_DONE'
                        }
                    ]
                },
                {
                    value: 'warehouse',
                    sortable: false,
                    filterable: false
                }
            ],
            orderProductColors: [],
            pagination: {
                currentPage: parseInt(currentPage),
                perPage: parseInt(perPage),
                total: 0,
                search: q || '',
                sortBy,
                descending: order.toLowerCase() === 'desc'
            },
            filters: JSON.parse(filters),
            selected: [],
            isLoading: false,
            appUrl: config.url
        };
    },

    computed: {
        ordersList() {
            const orderNumbers = this.orderProductColors.map(
                orderProductColor => orderProductColor.order.order_number
            );

            const uniqueOrderNumbers = [...new Set(orderNumbers)];

            return uniqueOrderNumbers.map((order, index) => ({
                orderNumber: order,
                rowClass: index % 2 ? 'row_dark' : ''
            }));
        }
    },

    async created() {
        this.fetchOrderProductColors();
    },

    methods: {
        ...mapActions({
            getOrderProductColors: 'orderProductColors/index'
        }),

        async fetchOrderProductColors() {
            this.isLoading = true;

            try {
                const options = {
                    ...this.pagination,
                    filters: this.filters
                };

                const { rows, count } = await this.getOrderProductColors(options);

                if (options.currentPage !== this.pagination.currentPage) {
                    return;
                }

                this.orderProductColors = rows;
                this.pagination.total = count;
                this.pagination.pages = Math.ceil(
                    this.pagination.total / this.pagination.perPage
                );
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }

            this.isLoading = false;
        },

        onPaginationChange(pagination) {
            this.pagination = {
                ...pagination
            };

            this.fetchOrderProductColors();
        },

        onSelectionChange(items) {
            this.selected = [...items];
        },

        onFilterChange(filters) {
            this.filters = { ...filters };
            this.pagination.currentPage = 1;

            this.fetchOrderProductColors();
        },

        getRowClass({ order }) {
            if (!order) {
                return '';
            }

            const t = this.ordersList.find(
                orderClass => orderClass.orderNumber === order.order_number
            );

            return t.rowClass;
        },

        getBadgeClass(value) {
            let color = 'success';

            if (value === 'HIGH') {
                color = 'warning';
            } else if (value === 'VERY_HIGH') {
                color = 'orange';
            } else if (value === 'EXPEDITED_DONE') {
                color = 'danger';
            }

            return `badge badge-${color}`;
        },

        getImpressionKitBadgeClass(value) {
            let color = 'success';

            if (value === true) {
                color = 'danger';
            }

            return `badge bg-soft-${color} text-${color}`
        },

        getDisplayableOrders(order, countHidden = false) {
            const currentOrderNumber = order.order_number || order.magento_order_number;

            const orders = order.customer.orders.map(order => ({
                order_number: order.order_number || order.magento_order_number
            }));

            const displayableOrders = orders
                .filter(order => order.order_number !== currentOrderNumber)
                .slice(0, 5);

            if (countHidden) {
                return orders.length - displayableOrders.length - 1;
            }

            return displayableOrders;
        },

        copyAddressToClipboard(order) {
            const text = `${order.customer.first_name} ${
                order.customer.last_name
            }\n${order.shipping_address}\n${order.shipping_city}\n${
                order.shipping_state
            }, ${order.shipping_zip}\n${order.shipping_country}`;

            return this.copyToClipboard(text);
        },

        async copyToClipboard(text) {
            await navigator.clipboard.writeText(text);

            this.$toaster('Copied to clipboard!');
        }
    }
};
</script>

<style lang="scss">
.row_dark {
    background-color: #e2e8ea !important;
}

.vs__dropdown-menu {
    min-width: 250px;
}
</style>
