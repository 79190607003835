var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('page-header',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"card-box"},[_c('awesome-table',{attrs:{"id":"logistics-center","labels":_vm.labels,"items":_vm.orderProductColors,"options":{
                addButton: false,
                sort: true,
                filters: true,
                columnsSelector: true,
                csvExport: false,
                isCard: false,
                tableStyle: 'font-size: 12px;'
            },"pagination":_vm.pagination,"is-loading":_vm.isLoading,"filters":_vm.filters,"get-row-class":_vm.getRowClass},on:{"row-click":function($event){return _vm.$router.push(("/logistics-center/" + ($event.id)))},"mouse-wheel-click":function($event){return _vm.$openNewTab(("/logistics-center/" + ($event.id)))},"pagination-change":_vm.onPaginationChange,"selection-change":_vm.onSelectionChange,"filter-change":_vm.onFilterChange},scopedSlots:_vm._u([{key:"items.order.order_number",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.order.order_number || item.order.magento_order_number)+"\n                "),_c('a',{staticClass:"action-icon",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.order.order_number || item.order.magento_order_number)}}},[_c('i',{staticClass:"far fa-copy"})])]}},{key:"items.gender",fn:function(ref){
            var item = ref.item;
return [(item.account.genderIdentifier === 'gender_men')?_c('span',[_vm._v("\n                    Man\n                ")]):(item.account.genderIdentifier === 'gender_women')?_c('span',[_vm._v("\n                    Woman\n                ")]):_vm._e()]}},{key:"items.need_impression_kit",fn:function(ref){
            var value = ref.value;
return [_c('span',{class:_vm.getImpressionKitBadgeClass(value)},[_vm._v("\n                    "+_vm._s(value === true ? 'YES' : 'NO')+"\n                ")])]}},{key:"items.order.customer.last_orders",fn:function(ref){
            var item = ref.item;
return [_vm._l((_vm.getDisplayableOrders(item.order)),function(order,index){return _c('span',{key:index},[_vm._v("\n                    "+_vm._s(order.order_number)+" "),_c('br')])}),(_vm.getDisplayableOrders(item.order, true))?_c('b',[_vm._v("\n                    "+_vm._s(((_vm.getDisplayableOrders(item.order, true)) + " more..."))+"\n                ")]):_vm._e()]}},{key:"items.order.customer.first_name",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n                "+_vm._s(((item.order.customer.first_name) + "\n                    " + (item.order.customer.last_name)))+"\n            ")]}},{key:"items.print_sticker",fn:function(ref){
                    var item = ref.item;
return [_c('button',{staticClass:"btn btn-xs btn-info",on:{"click":function($event){$event.stopPropagation();return _vm.dymoPrintLabel(item, 'order', true)}}},[_vm._v("\n                    Order\n                ")]),_c('button',{staticClass:"btn btn-xs btn-info ml-1",on:{"click":function($event){$event.stopPropagation();return _vm.dymoPrintLabel(item, 'fda')}}},[_vm._v("\n                    FDA\n                ")]),_c('button',{staticClass:"btn btn-xs btn-blue ml-1",on:{"click":function($event){$event.stopPropagation();return _vm.dymoPrintLabel(item, 'both', true)}}},[_vm._v("\n                    Both\n                ")])]}},{key:"items.outgoing_tracking",fn:function(ref){
                    var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.outgoing_tracking || '-')+"\n                "),(item.outgoing_tracking)?_c('a',{staticClass:"action-icon",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.outgoing_tracking)}}},[_c('i',{staticClass:"far fa-copy"})]):_vm._e()]}},{key:"items.incoming_tracking",fn:function(ref){
                    var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.incoming_tracking || '-')+"\n                "),(item.incoming_tracking)?_c('a',{staticClass:"action-icon",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.incoming_tracking)}}},[_c('i',{staticClass:"far fa-copy"})]):_vm._e()]}},{key:"items.scan_link",fn:function(ref){
                    var item = ref.item;
return [(item.scan_link)?_c('a',{staticClass:"btn btn-xs btn-blue",attrs:{"href":item.scan_link,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("\n                    3D Scan\n                ")]):_vm._e()]}},{key:"items.order.shipping_address",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('span',[_vm._v("\n                        "+_vm._s(((item.order.customer.first_name) + "\n                            " + (item.order.customer.last_name)))+"\n                        "),_c('br'),_vm._v("\n                        "+_vm._s(item.order.shipping_address)+" "),_c('br'),_vm._v("\n                        "+_vm._s(item.order.shipping_city)+" "),_c('br'),_vm._v("\n                        "+_vm._s(((item.order.shipping_state) + ",\n                            " + (item.order.shipping_zip)))+"\n                        "),_c('br'),_vm._v("\n                        "+_vm._s(item.order.shipping_country)+"\n                    ")]),_c('a',{staticClass:"action-icon ml-1",on:{"click":function($event){$event.stopPropagation();return _vm.copyAddressToClipboard(item.order )}}},[_c('i',{staticClass:"far fa-copy"})])])]}},{key:"items.productColor.product.sku",fn:function(ref){
                            var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.productColor.product.sku || item.assigned_sku)+"\n            ")]}},{key:"items.order.customer.orders_count",fn:function(ref){
                            var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.order.customer.orders.length)+"\n                "),_c('a',{staticClass:"action-icon",attrs:{"href":(_vm.appUrl + "/customers/" + (item.order.customer_id) + "/orders"),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"fe-external-link"})])]}},{key:"items.priority",fn:function(ref){
                            var value = ref.value;
return [_c('span',{class:_vm.getBadgeClass(value)},[_vm._v("\n                    "+_vm._s(_vm._f("startCase")(value))+"\n                ")])]}},{key:"items.warehouse",fn:function(ref){
                            var value = ref.value;
return [_vm._v("\n                "+_vm._s(value.name)+"\n            ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }